.flex-container {
    display: flex;
}
.module:hover .delete{
    visibility: visible;
}

.flex-child{
    flex: 1;
}

#title{
    text-decoration: underline;
}
li{
    margin: 4px;
}
.delete{
    margin: auto 0;
    visibility: hidden;
}
.grid-container {
    display: grid;
}
.grab-cursor{
    cursor: grab;
}


.target-drop-module{
    flex-direction: column;
    display: flex;
    align-items: center;
}

.module-can-swap-drop{
    border-style: dashed;
    border-color: #66d2e8;
    border-width: 2px;
}