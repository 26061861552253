.filAriane_page--current {
    box-shadow: none;
    font-weight: 600;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
}

.filAriane {
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: #ff8500;
    padding: 0 20px 0 20px;
    width: fit-content;
    margin-left: 60px;
    border-radius: 10px;
    height: 30px;
    color: rgb(255, 255, 255);
    margin-top: 0;
    margin-bottom: -25px;
}

.filAriane_page {
    display: flex;
    align-items: center;
    height: 100%;
}

.filAriane_separator {
    color: white;
    font-size: 50px;
    height: 100%;
}
