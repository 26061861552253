.flex-container {
    display: flex;
}
.element:hover .delete{
        visibility: visible;
}

.flex-child{
    flex: 1;
}

#title{
    text-decoration: underline;
}
.puce{
    flex: none;
    margin-right: 16px;
    font-size:24px
}

#add-element {
    border: none;
    border-radius: 20px;
    padding-left: 7px;
    padding-right: 7px;
    color: gray;
}

#add-element:hover {
    background-color: #66d2e8; /* On modifie la couleur du fond au passage de la souris */
    color: white;
}

li{
    margin: 4px;
}

.required{
    margin-left: 32px;
    list-style-type: circle;
}

.delete{
    visibility: hidden;
}

.no-element{
    font-style: italic;
    color: darkgray;
    margin: 9px;
}
.bold{
    font-weight: bold;
}

.drop{
    border: #282c34 solid 1px;
    border-radius: 5px;
}
.selectedSection{
    position: relative
}


.drop-btn{
    padding:20px;    
    border-color: black!important;
    border-style: solid!important;
    border-width: 2px!important;
    color:white!important;
    width: 4500px;
    max-width: 450px;
    height: 100px;
    max-height: 100px;
}

.drop-btn-waiting{
    background-color: #ffb666;
    color: white;
    border-style: dotted!important;
    border-width: 2px;
    border-color: #282c34;
}

.drop-btn-hover{
    color: white;
    background-color: #66d2e8;
    border-style: solid!important;
    border-color: #66d2e8;
    border-width: 2px;
}

.selectedSection:after{
    content: "";
    width: 100%;
    height: 0;
    border-bottom: #66d2e8 5px solid;

    position: absolute;
    left: -5px;
    bottom: -5px;
}