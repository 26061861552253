.container-program-title {
    display: flex;
    width:100% ;
}

#program-title{
    text-align: center;
    font-weight: bold;
    border: none;
    border-bottom: 1px;
    background-color: transparent;
    font-size: 32px;
    outline: none;
    width: 100%;
    resize: none;
    font-family: 'Poppins', sans-serif;
    color: rgb(17, 0, 162);
}

.program-title-icon-pen {
    color: rgb(17, 0, 162);
    margin: 10px 0px 0px 0px;
    cursor: pointer;
}

.program-title {
    margin:0px 0px 10px 0px;
    padding: 0px;
    text-align: center;
    color: #000;
    font-size: 32px;
}

.program-title {
    margin:0px 0px 10px 0px;
    padding: 0px;
    text-align: center;
    color: #000;
    font-size: 32px;
}

#program-subtitle{
    text-align: center;
    font-weight: lighter;
    font-style: italic;
    border: none;
    border-bottom: 1px;
    background-color: transparent;
    font-size: 32px;
    outline: none;
    width: 100%;
    resize: none;
    font-family:  'Poppins',
    'cursive';
}

#program-subtitle-h1{
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    padding: 0;
}

#program-logo{
    max-height: 100px;
    z-index: var(--headers-index);
}


.textfield:focus{
    border: 0;
    border-bottom: 2px solid #555;
}

.divTextfield{
    /*padding: 20px;*/
    outline: 0px;
}