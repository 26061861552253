.tabItem{
    display: flex;
    width: 100px;
    height: 100px;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
}


.tabItem:hover{
    background-color: #0090AD !important;
}

.thin-scrollbar::-webkit-scrollbar {
    scrollbar-width: thin   
}


  .thin-scrollbar::-webkit-scrollbar-thumb{
    border: 0px solid var(--scroll-bar-bg-color);
    background: gray;
    border-radius: 20px;
    width: 3px!important;
 } 