.title_home{
    color: #1100A2;
    font-size: 50px;
    font-family: 'Poppins';
    padding-left: 5vw;
    margin-top: 10vh;
    margin-bottom: 0px;
    font-weight: bold;
}

.under_title{
    margin: 30px 0px; 
}

.home_list{
    color: #4e4e4e;
}
.home_list>li{
    margin:30px 0px 30px -23px;
}

.top_page{
    height: 70px;
}


.body_page{
    padding: 0 10vw 0 10vw;
}

img.main_page {
    width: 100%;
    height: auto;
    max-height: 380px;
}

img { /* this is BAD */
    float: right;
    height: 383px;
}
