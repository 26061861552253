@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

h1, h2, h3, h4{
  font-family: 'Poppins', bold;
} 

h1{
  color: #1101a2;
  font-weight: bold;
  font-size: 2.5rem;
  text-align: left;
  margin-top: 130px;
  padding-left: 10%;
}

div, p{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

button{
  font-family: 'Poppins', medium;
  font-weight: 500;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins' sans-serif;
}
