.MuiTabs-flexContainer{
    text-align: left;
}

.topDiv {
    background-color: #f6f6f6;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
}

#containerSearchVierge {
    display: flex;
    flex-direction: row;
}

#containerSearchVierge > div {
    text-align: center;
}

.searchDiv, .viergeDiv {
    background-color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.searchDiv {
    margin-right: 10px;
    width: 70%;
}

#containerSearchVierge > div {

}

.viergeDiv {
    width: 30%;
}

.cardHeader {
    color: #ff8500;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
}

.lastDiv {
    background-color: #1100a2;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 35px;
}

#biblioDiv {
    color: white;
    border-bottom: 4px solid white;
    padding-right: 3%;
    padding-left: 3%;
}

#biblioDiv > h3 {
    margin-bottom: 5px;
}

#rightDiv {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* flex element to the right */
    margin-left: auto;
    padding-right: 5%;
}

#topDivOfLastDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.template {
    background-color: white;
    margin: 15px auto 0 auto;
    padding: 30px;
}

.css-10mlprc-MuiInputBase-root-MuiInput-root:after{
    border-bottom: 0 !important;
}


.css-10mlprc-MuiInputBase-root-MuiInput-root:before{
    border-bottom: 0 !important;
}

