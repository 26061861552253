.Headers-logo{
    height: 48px;
    z-index: var(--headers-index);
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    border-right: 1px solid grey;
}

.Headers-title {
    text-align: center;
    width: fit-content;
    position: relative;
    z-index: var(--headers-index);

}

.Headers{
    position: fixed;
    align-items: center;
    top: 0;
    left: 0;
    background-color: white;
    padding-top: auto;
    padding-bottom: auto;
    --headers-index: 1300;
    z-index: var(--headers-index);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    height: 90px;
    margin-top: -100px;
}