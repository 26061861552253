.type{
    font-style: italic;
}


.card{
    transition: all .3s  ease-in-out;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

}
.card:hover {
    transform: scale(1.05);

    cursor: move!important; /* fallback if grab cursor is unsupported */
    cursor: grab!important;
    cursor: -moz-grab;
    cursor: -webkit-grab;

}

.cursor-grab{
    cursor: move!important; /* fallback if grab cursor is unsupported */
    cursor: grab!important;
    cursor: -moz-grab;
    cursor: -webkit-grab;

}
